@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&family=Paytone+One&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;
html,
body {
  padding: 0;
  margin: 0;
  background-color: #E8E1E5;
}

.landing {
  margin-top: -128px;
}
.gradient {
  background-image: linear-gradient(
    115deg,
    hsl(284deg 81% 86%) 0%,
    hsl(276deg 74% 84%) 19%,
    hsl(268deg 68% 82%) 27%,
    hsl(260deg 63% 81%) 34%,
    hsl(252deg 58% 79%) 41%,
    hsl(244deg 53% 77%) 47%,
    hsl(237deg 50% 75%) 53%,
    hsl(230deg 49% 71%) 59%,
    hsl(225deg 48% 67%) 66%,
    hsl(220deg 47% 64%) 73%,
    hsl(216deg 46% 60%) 81%,
    hsl(212deg 45% 56%) 100%
  );
}


body.active-modal {
  overflow-y: hidden;
}

.btn-modal {
  display: block;
}

.modal, .overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
}

.overlay {
  background: rgba(49,49,49,0.8);
}
.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #f1f1f1;
  padding: 14px 28px;
  border-radius: 3px;
  max-width: 600px;
  min-width: 300px;
  box-shadow: 8px 8px 8px 6px rgba(18, 18, 18, 0.2);
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 7px;
}

.bottom-curve{
  margin-top: -50px;
}
@media screen and (min-width: 1200px) {
  .curve {
    margin-top: -120px;
  }
  .about-text {
    padding-top: -150px;
  }
}